<template>
  <div class="highscore">
    <h1>ManyPong Highscore</h1>
    <p>This is where you find out how bad you really are.</p>
    <ul>
      <li v-for="item in [2021, 2022, 2024, 2025]" v-bind:key="item">
        <span v-bind:class="[item == highscoreYear ? 'active' : '', 'clickable']" @click="setHighscoreYear(item)">
          <b>{{item}}</b>
        </span>
      </li>
    </ul>
    <header>
      <h2>Highscore {{ highscoreYear }}</h2>
    </header>
    <table>
      <thead>
        <tr>
          <th v-for="(value, key, index) in keysTitles" :key=key v-on:click="reorder(key)"
            v-bind:class="[key === sorting ? order : '', index > 1 ? 'clickable' : '']">
            {{ value }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(entry, index) in highscoreSorted" :key="entry.id">
          <td v-for="(value, key) in keysTitles" :key=key>{{ format(entry[key], key, index) }}</td>
        </tr>
      </tbody>
    </table>
    <p>* Rank is calculated by aggregating the placement in each of the four highscore categories (games, wins, points
      and ratio).</p>
  </div>
</template>

<script>
import { statsStore } from '@/store/statsStore'
export default {
  // inject: ['statsStore'],
  data() {
    return {
      // keys: ['rank', 'displayName', 'games', 'wins', 'points', 'ratio'],
      keysTitles: { 'place': '', 'displayName': 'Player', 'rank': 'Rank*', 'games': 'Games', 'wins': 'Wins', 'points': 'Points', 'ratio': 'Ratio' },
      // titles: ['Name', 'Games', 'Wins', 'Points', 'Ratio'],
      sorting: 'rank',
      order: 'descending',
      useOrder: false,
    };
  },
  computed: {
    highscoreYear() {
      return statsStore.state.highscoreYear
    },
    highscoreSorted() {
      let result = statsStore.highscoreFull;
      switch (this.sorting) {
        case 'games':
        default:
          result.sort((a, b) => b.games - a.games || a.displayName.localeCompare(b.displayName))
          break
        case 'wins':
          result.sort((a, b) => b.wins - a.wins || a.displayName.localeCompare(b.displayName))
          break
        case 'points':
          result.sort((a, b) => b.points - a.points || a.displayName.localeCompare(b.displayName))
          break
        case 'ratio':
          result.sort((a, b) => b.ratio - a.ratio || a.displayName.localeCompare(b.displayName))
          break
        case 'rank':
          result.sort((a, b) => a.rank - b.rank || a.displayName.localeCompare(b.displayName))
          break
      }

      if (this.order === 'ascending' && this.useOrder) {
        result.reverse()
      }

      return result
    }
  },
  methods: {
    setHighscoreYear(year) {
      console.log('year', year)
      statsStore.setHighscoreYear(year)
    },
    format(value, type, index) {
      if (value === -1) {
        return '-'
      }
      switch (type) {
        case 'ratio':
          return Math.round(value * 100) + '%'
        case 'place':
          // if(index < 3) {
          //   return ['🥇', '🥈', '🥉'][index]
          // }
          return index + 1
        case 'rank':
          if (value < 4) {
            return ['🥇', '🥈', '🥉'][value - 1]
          }
          return value
        default:
          return value
      }
    },
    reorder(key) {
      if (key === this.sorting && this.useOrder) {
        this.order = this.order === 'descending' ? 'ascending' : 'descending'
        return
      }
      // console.log('reorder', key, this.sorting, this.order)
      this.sorting = key
    }
  }
}
</script>

<style scoped lang="scss">
.highscore {
  .active {
      color: #e92566;
  }
  .clickable {
    cursor: pointer;
    text-decoration: underline;
  }

  table {
    width: 100%;

    tr {
      text-align: left;
    }

    th,
    td {
      position: relative;
      text-align: center;

      &:nth-child(2) {
        text-align: left;
      }
    }

    // th::after {
    //   position: absolute;
    //   top: -2px;
    // }
    // th.ascending::after {
    //   content: "🏆";
    //   opacity: 1;
    // }
    // th.descending::after {
    //   content: "🏆";
    //   opacity: 1;
    // }
    th.ascending,
    th.descending {
      color: #e92566;
    }
  }

  ul {
    list-style-type: none;
    display: flex;
    // align-items: center;

    li:not(:last-of-type) {
      margin-right: 10px;
    }

    // li:last-child {
    //   margin-left: auto;
    // }
  }
}
</style>